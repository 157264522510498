<template>
  <div>
    <input id="my-file" type="file" name="my-file" ref="fu" style="display: none;" @change="onFileChange" />
    <editor
      api-key="3mdiybmlq2woilzjmgspal9plfblqc50ikssrcgxzp14vwxl"
      :initial-value="modelValue"
      v-model="editedValue"
      @mouseleave="update"
      :init=initial
    />
  </div>
</template>

<script>

import Editor from '@tinymce/tinymce-vue'
import PhotoUploadService from '../../../services/admin/photos/service';
import FileUploadService from '../../../services/admin/attachments/service';
import {vplugin} from './tiny_plugins/tiny-video-select'
// import {customPlugin} from '@/plugins/mybuttonMenu'

export default {
  name: 'TinyMceEditor',
  props: {
    modelValue: String,
    model_name: {
      type: String,
      default: ""
    },
    model_id: {
      type: Number,
      default: 0
    },
    simlpe: {
      type: Boolean,
      default: false
    },
    image: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue'],
  computed: {
    initial(){
      let theToolbar = 'styleselect | bold italic backcolor | \
          link image media fullscreen | \
          bullist numlist | iframe | code | \
          alignleft aligncenter alignright alignjustify | outdent indent | removeformat'
      if (!this.image) {
        theToolbar = theToolbar.replace('image ', '')
      }
      if (this.simlpe) {
        theToolbar = 'styleselect | bold italic | \
          link fullscreen | \
          alignleft aligncenter alignright alignjustify'
      }
      return {
        height: this.heightx,
        menubar: false,
        plugins: [
          'advlist autolink lists powerpaste link image charmap print preview anchor code media',
          'searchreplace visualblocks code fullscreen',
          'insertdatetime media table code help wordcount video_plugin'
        ],
        media_dimensions: false,
        toolbar: theToolbar,
        statusbar: false,
        extended_valid_elements: 'script[language|type|src]',
        content_style: 'img {max-width: 100%; height: auto;}',
        file_picker_callback: this.upload,
        images_upload_handler: this.uploadImg,
        relative_urls : false,
        video_plugin: {
          ajaxurl: '/call_to_actions/tiny_select'
        },
        setup: function() {
          // window.tinymce.PluginManager.add('customButtonMenu', customPlugin)
          window.tinymce.PluginManager.add('video_plugin', vplugin)
        }
      }
    }
  },
  components: {
    'editor': Editor
  },
  data() {
    return {
      heightx: 500,
      editedValue: this.modelValue,
      f: []
    }
  },
  watch: { 
    modelValue: {
      deep: true,
      handler: function(newVal ){
          // console.log('Props modelValue changed: ', newVal, ' | was: ', oldVal);
        this.editedValue = newVal;
      }
    }
  },
  // mounted() {
  //   this.editedValue = this.modelValue
  // },
  methods: {
    update() {
      //this.$emit("input", this.editedValue);
      this.$emit('update:modelValue', this.editedValue) 
    },
    async uploadImg(blobInfo, success, failure) {
      const model = this.model_name
      const model_id = this.model_id

      // console.log('uploading img method')
      try {
        const data = await PhotoUploadService.getCredentials(blobInfo.blob().name);
        
        var options = {
          headers: {
            "Content-Type": blobInfo.blob().type
          }
        };

        await PhotoUploadService.uploadToS3(data.data.url, blobInfo.blob(), options);

        const us = await PhotoUploadService.attachmentToModel({
          key: data.data.key,
          id: model_id,
          mimetype: blobInfo.blob().type,
          model: model
        })
        
        success(us.data.big);
      } catch (err) {
        console.log(err);
        failure('Something went wrong uploading the image');
      }
    },
    async upload(callback) {
      console.log('uploading file')
      const model = this.model_name
      const model_id = this.model_id

      // console.log('uploading method')
      const input = document.getElementById("my-file")
      input.click()
      
      input.setAttribute("type", "file");
      // input.setAttribute('accept', 'image/*');

      (input.onchange = async function () {
        var file = this.files[0];
        
        try {
          const data = await FileUploadService.getCredentials(file.name);
          
          var options = {
            headers: {
              "Content-Type": file.type
            }
          };

          await FileUploadService.uploadToS3(data.data.url, file, options);
          
          const us = await FileUploadService.attachmentToModel({
            key: data.data.key,
            id: model_id,
            mimetype: file.type,
            model: model
          })
          
          callback(us.data.src, {text: file.name})
        } catch (err) {
          console.log(err);
        } 
        
      })
    },
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return;
      }
      this.f = files[0]
      this.chosen = true
      
    }
  }
}
</script>