const vplugin = function(editor) {
  var _urlDialogConfig = {
    title: "Choose call to action to insert",
    url: editor.settings.video_plugin.ajaxurl,
    buttons: [
      {
        type: "custom",
        name: "action",
        text: "Submit",
        primary: true,
      },
      {
        type: "cancel",
        name: "cancel",
        text: "Close Dialog",
      },
    ],
    onAction: function(instance, trigger) {
      // do something
      console.log(trigger);
      editor.windowManager.alert("onAction is running. You can code your own onAction handler within the plugin.");
      // close the dialog
      instance.close();
    },
    width: 600,
    height: 300,
    onMessage: function(instance, data) {
      // we can do something here with the
      // instance and the data of the message
      switch (data.mceAction) {
        case "sendUrl":
          if (data.data.id == "") {
            // display an error
            editor.windowManager.alert("You need to send an id.");
          } else {
            // editor.windowManager.alert('url sent: ' + data.data.url);
            editor.insertContent(`[cta id="${data.data.id}" title="${data.data.title}"]${data.data.title}[/cta]`);
            // close the window
            instance.close();
          }
          break;
        case "insertContent":
          // run code for inserting content
          break;
        case "replaceContent":
          // run code for replacing the content
          break;
      }
    },
  };

  editor.ui.registry.addButton("iframe", {
    text: "CTA",
    icon: "embed",
    onAction: () => {
      editor.windowManager.openUrl(_urlDialogConfig);
    },
  });
};

export { vplugin };
