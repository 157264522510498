import axios from 'axios';
import authHeader from '../../auth-header';

const API_URL = process.env.VUE_APP_API_BASE_URL;

class UploadService {
  
  getCredentials(filename) {
    return axios.get(API_URL + `admin/uploader/getCredentials?filename=${filename}&type=file`, { headers: authHeader() });
  }

  uploadToS3(url, file, options) {
    return axios.put(url, file, options);
  }

  attachmentToModel(data) {
    return axios.post(API_URL + `admin/uploader/attach?type=file`, {...data}, { headers: authHeader() });
  }

  deleteFile(id) {
    return axios.delete(API_URL + `admin/uploader/attachments/${id}`, { headers: authHeader() });
  }

}

export default new UploadService();
